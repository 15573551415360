<template>
  <v-container class="d-block">
    <question
      @textLoaded="setBaseQuestion($event, 'text')"
      @fileIdLoaded="setBaseQuestion($event)"
      :type="'base'"
      :fillData="data.baseQuestion"
    />
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab
        class="d-flex justify-center"
        v-for="(item, index) in data.items"
        :key="index"
      >
        <div class="d-flex justify-space-between">
          <span>{{ $t("question") }} {{ index + 1 }}</span>
          <v-divider v-if="tab == index" class="mx-4" vertical></v-divider>
          <v-icon v-if="tab == index" @click.prevent.stop="copyTab(index)"
            >mdi-content-copy</v-icon
          >
        </div>
      </v-tab>
      <v-icon class="ma-6" @click="deleteItem">mdi-minus-thick</v-icon>
      <v-icon class="ma-6" @click="addItem">mdi-plus-thick</v-icon>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in data.items" :key="index">
        <v-card flat>
          <v-row class="mt-4">
            <question
              @textLoaded="setQuestion($event, 'text', index)"
              @fileIdLoaded="setQuestion($event, 'audio', index)"
              :label="'question'"
              :fillData="item"
            />
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
export default {
  props: ["type", "schema", "exerciseType"],
  data() {
    return {
      name: "voice_record",
      tab: 1,
      data: {
        baseQuestion: {
          text: null,
          image: null,
          audio: null,
        },
        items: [
          {
            text: null,
            image: null,
            audio: null,
          },
        ],
      },
    };
  },
  methods: {
    setBaseQuestion(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setQuestion(data, type, index) {
      if (type == "text") {
        this.data.items[index].text = data;
      } else {
        this.data.items[index].audio = data;
      }
      this.$emit("getData", this.data);
    },
    addItem() {
      this.data.items.push({
        text: null,
        image: null,
        audio: null,
      });
      // this.checkDisable();
    },
    deleteItem() {
      this.data.items.pop();
      // this.checkDisable();
    },
    checkDisable() {
      if (
        (this.baseQuestion.text == null) |
        (this.baseQuestion.text == "") |
        (this.baseQuestion.audio == null)
      ) {
        this.$emit("checkDisable", true);
      } else {
        this.$emit("checkDisable", false);
      }
    },
  },
  created() {
    // this.$emit("checkDisable", true);

    if (this.type == "edit") {
      if (this.name == this.exerciseType) {
        this.data = this.schema;
      }
    }
  },
};
</script>
