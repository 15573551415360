<template>
  <v-container class="d-block">
    <question
      @textLoaded="setBaseQuestion($event, 'text')"
      @fileIdLoaded="setBaseQuestion($event)"
      :type="'base'"
      :fillData="data.baseQuestion"
    />
    <question
      @textLoaded="setQuestion($event, 'text')"
      @fileIdLoaded="setQuestion($event)"
      :label="'question'"
      :fillData="data.question"
    />
    <v-row class="justify-space-around mt-2 mb-2">
      <span>{{ $t("question level") }} </span>
      <v-switch
        inset
        @click="setLevel('easy')"
        v-model="easy"
        class="ma-2"
        :label="$t('easy')"
      ></v-switch>
      <v-switch
        inset
        @click="setLevel('middle')"
        v-model="middle"
        class="ma-2"
        :label="$t('middle')"
      ></v-switch>
      <v-switch
        inset
        @click="setLevel('hard')"
        v-model="hard"
        class="ma-2"
        :label="$t('hard')"
      ></v-switch>
    </v-row>
    <video-preview
      :data="data.image"
      @getVideoId="setData($event)"
      @cancelFile="setData($event)"
    ></video-preview>
  </v-container>
</template>
<script>
export default {
  props: ["type", "schema", "exerciseType"],

  data() {
    return {
      name: "puzzle",
      data: {
        baseQuestion: {
          text: null,
          image: null,
          audio: null,
        },
        question: {
          text: null,
          image: null,
          audio: null,
        },
        questionLevel: "easy",
        image: null,
      },
      easy: true,
      middle: false,
      hard: false,
    };
  },
  methods: {
    setLevel(level) {
      switch (level) {
        case "middle":
          this.easy = false;
          this.hard = false;
          this.middle = true;
          this.data.questionLevel = "middle";
          break;
        case "hard":
          this.middle = false;
          this.easy = false;
          this.hard = true;
          this.data.questionLevel = "hard";
          break;
        default:
          this.middle = false;
          this.hard = false;
          this.middle = false;
          this.easy = true;
          this.data.questionLevel = "easy";
          break;
      }
      this.$emit("getData", this.data);
    },
    setBaseQuestion(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setQuestion(data, type) {
      if (type == "text") {
        this.data.question.text = data;
      } else {
        this.data.question.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setData(data) {
      this.data.image = data;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    checkDisable() {
      if (
        (this.data.baseQuestion.text == "") |
        (this.data.baseQuestion.text == null) |
        (this.data.baseQuestion.audio == null) |
        (this.data.image == null)
      ) {
        this.$emit("checkDisable", true);
      } else {
        this.$emit("checkDisable", false);
      }
    },
  },
  created() {
    // this.$emit("checkDisable", true);
    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
        this.setLevel(this.data.questionLevel);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";

span {
  color: $myBlue;
  font-weight: 700;
}
</style>
