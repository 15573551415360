<template>
  <v-container class="d-block mt-2 mb-2 groub">
    <v-row class="justify-start">
      <v-col lg="3"
        ><h3 class="mt-n2">{{ $t("group") }}{{ index }}</h3></v-col
      >
    </v-row>
    <question
      @textLoaded="setBaseData($event, 'text')"
      @fileIdLoaded="setBaseData($event)"
      :fillData="data.question"
      :type="'base'"
      :label="'question'"
    />
    <v-row class="mt-n8 justify-center px-6">
      <v-col v-for="(item, i) in data.options" :key="i" cols="10" lg="3">
        <answer-option :data="item" @dataLoaded="setData($event, i)" />
      </v-col>
      <div class="d-flex align-center">
        <v-icon @click="deleteItems" class="ma-2">mdi-minus-thick</v-icon>
      </div>
      <div class="d-flex align-center">
        <v-icon @click="addItems" class="ma-2">mdi-plus-thick</v-icon>
      </div>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["index", "fillData"],
  data() {
    return {
      data: {
        question: {
          text: null,
          audio: null,
          image: null,
        },
        options: [{ image: null, text: null, audio: null, type: "text" }],
      },
    };
  },
  methods: {
    setBaseData(data, type) {
      if (type == "text") {
        this.data.question.text = data;
      } else {
        this.data.question.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setData(data, i) {
      this.data.options[i] = data;
      this.$emit("getData", this.data);
    },
    addItems() {
      this.data.options.push({
        image: null,
        text: null,
        audio: null,
        type: "text",
      });
      this.$emit("getData", this.data);
    },
    deleteItems() {
      this.data.options.pop();
      this.$emit("getData", this.data);
    },
    // checkDisable() {
    //   if (
    //     (this.data.question.text == null) |
    //     (this.data.question.text == "") |
    //     (this.data.question.audio == null)
    //   ) {
    //     this.$emit("checkDisable", false);
    //     return;
    //   }
    //   this.data.options.forEach(item => {
    //     if(item.info==)
    //   });
    // }
  },
  created() {
    if (this.fillData) {
      this.data = this.fillData;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";

.add-box {
  width: 20%;
  height: 150px;
  margin: auto;
  border: 1px solid grey;
  cursor: pointer;
}
.icon {
  width: 50% !important;
  height: 50% !important;
  color: grey !important;
}
.drag-drob {
  height: 200px;
  border: 1px solid grey;
  margin: auto;
}
.groub {
  width: 80%;
  border: 2px solid $myBlue;
  border-radius: 10px;
}
span {
  color: $myBlue;
  font-weight: 600;
}
</style>
