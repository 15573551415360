<template>
  <v-container style="display: block;">
    <v-row class="mb-4">
      <question
        @textLoaded="setBaseQuestion($event, 'text')"
        @fileIdLoaded="setBaseQuestion($event)"
        :type="'base'"
        :fillData="data.baseQuestion"
      />
    </v-row>
    <v-row class="ma-auto mb-n2">
      <question
        @textLoaded="setQuestion($event, 'text')"
        @fileIdLoaded="setQuestion($event)"
        :label="'question'"
        :fillData="data.question"
      />
    </v-row>
    <video-preview
      @getVideoId="getFile($event)"
      @cancelFile="getFile(null)"
      :data="data.video_id"
      :type="'video'"
    ></video-preview>
  </v-container>
</template>
<script>
export default {
  props: ["type", "schema", "exerciseType"],
  data() {
    return {
      name: "video",
      data: {
        baseQuestion: {
          text: "",
          image: null,
          audio: "",
        },
        question: {
          text: "",
          image: null,
          audio: "",
        },
        video_id: null,
      },
    };
  },
  methods: {
    setBaseQuestion(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setQuestion(data, type) {
      if (type == "text") {
        this.data.question.text = data;
      } else {
        this.data.question.audio = data;
      }
      this.$emit("getData", this.data);
    },
    action() {
      this.$router.push("/Exercise/end-exercise");
    },

    getFile(videoId) {
      if (videoId) {
        this.data.video_id = videoId;
        this.$emit("getData", this.data);
        this.checkDisable();
      } else {
        this.$emit("checkDisable", true);
        this.checkDisable();
      }
    },
    checkDisable() {
      console.log(this.data);
      for (const key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          if (key === "baseQuestion") {
            if (
              this.data.baseQuestion.text === "" ||
              this.data.baseQuestion.audio === "" ||
              this.data.video_id === "" ||
              this.data.video_id === null
            ) {
              return true;
            }
          } else if (key === "question") {
            if (
              this.data.question.text === "" ||
              this.data.question.audio === ""
            ) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },

  created() {
    this.$emit("checkDisable", true);
    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.checkDisable();
      },
    },
  },
};
</script>
