<template>
  <v-container style="display: block;">
    <question
      @textLoaded="setBaseQuestion($event, 'text')"
      @fileIdLoaded="setBaseQuestion($event)"
      :type="'base'"
      :fillData="data.baseQuestion"
    />
    <question
      @textLoaded="setQuestion($event, 'text')"
      @fileIdLoaded="setQuestion($event)"
      :label="'question'"
      :fillData="data.question"
    />
    <v-row class="mb-4 mt-4 justify-space-around">
      <h5>{{ $t("add_word") }}</h5>
    </v-row>
    <v-row v-for="(item, i) in data.items" :key="i" class="mt-n4 mb-n4">
      <question
        @textLoaded="setData($event, i, 'text')"
        @fileIdLoaded="setData($event, i)"
        :fillData="item"
      />
    </v-row>
    <v-row class="justify-center">
      <v-icon @click="addItem" class="ma-4" x-large>mdi-plus-thick</v-icon>
      <v-icon @click="deleteItem" class="ma-4" x-large>mdi-minus-thick</v-icon>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["type", "schema", "exerciseType"],
  data() {
    return {
      name: "cross_words",
      data: {
        baseQuestion: {
          text: null,
          image: null,
          audio: null,
        },
        question: {
          text: null,
          image: null,
          audio: null,
        },
        items: [
          {
            text: null,
            image: null,
            audio: null,
          },
        ],
      },
    };
  },
  methods: {
    setBaseQuestion(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      // this.$emit("getData", this.data);
    },
    setQuestion(data, type) {
      if (type == "text") {
        this.data.question.text = data;
      } else {
        this.data.question.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setData(data, i, type) {
      if (type == "text") {
        this.data.items[i].text = data;
      } else {
        this.data.items[i].audio = data;
      }
      this.$emit("getData", this.data);
      this.checkDisable();
    },
    fileCanceled() {
      this.baseQuestion.audio = null;
      this.checkDisable();
    },

    checkDisable() {
      if (
        this.data.baseQuestion.text == null ||
        this.data.baseQuestion.text == "" ||
        this.data.baseQuestion.audio == null
      ) {
        this.$emit("checkDisable", true);
        return; // exit the function when the base question has null or empty values
      }

      for (let i = 0; i < this.data.items.length; i++) {
        const item = this.data.items[i];
        if (item.text == null || item.text == "" || item.audio == null) {
          this.$emit("checkDisable", true);
          return; // exit the function when an item has null or empty values
        }
      }

      this.$emit("checkDisable", false);
    },
    addItem() {
      this.data.items.push({
        text: null,
        image: null,
        audio: null,
      });
      this.checkDisable();
    },
    deleteItem() {
      this.data.items.pop();
      this.checkDisable();
    },
  },
  created() {
    this.$emit("checkDisable", true);
    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";

h5 {
  color: $myBlue;
  font-weight: 600;
}
</style>
