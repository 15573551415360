<template>
  <v-row class="mt-n8 justify-center px-6">
    <v-col v-for="(item, i) in options" :key="i" cols="10" lg="3">
      <answer-option
        @dataLoaded="setData($event, i)"
        v-bind:data="type == 'edit' ? item : null"
        :type="'rearrange'"
        :itemNumber="i"
      />
    </v-col>
    <div class="d-flex align-center">
      <v-icon class="ma-2" x-large @click="deleteItems">mdi-minus-thick</v-icon>
    </div>
    <div class="d-flex align-center">
      <v-icon class="ma-2 my-8" x-large @click="addItems"
        >mdi-plus-thick</v-icon
      >
    </div>
  </v-row>
</template>
<script>
import answerOption from "@/components/mycomponent/preview/choose-answer-option.vue";
export default {
  props: ["type", "data"],
  components: {
    answerOption,
  },
  data() {
    return {
      dialog: false,
      src: null,
      options: null,
    };
  },
  methods: {
    setData(data, index) {
      this.options[index] = data;
      this.$emit("dataLoaded", this.options);
    },
    addItems() {
      this.options.push({ type: "text" });
      this.$emit("dataLoaded", this.options);
    },
    deleteItems() {
      this.options.pop();
      this.$emit("dataLoaded", this.options);
    },
  },
  created() {
    if (this.data) {
      this.options = this.data;
    }
  },
};
</script>
