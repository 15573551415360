<template>
  <v-container style="display: block;">
    <question
      @textLoaded="setBaseQuestion($event, 'text')"
      @fileIdLoaded="setBaseQuestion($event)"
      :type="'base'"
      :fillData="data.baseQuestion"
    />
    <v-row class="px-10 mt-n12 justify-center">
      <v-checkbox
        v-model="data.autoPlay"
        :label="$t('Switch exercise automatically after answer')"
      ></v-checkbox>
    </v-row>
    <rank-group
      v-for="(item, i) in data.items"
      :key="i"
      @getData="setData($event, i)"
      :fillData="type == 'edit' ? item : null"
      :index="i + 1"
    />
    <v-row class="justify-center">
      <v-icon @click="addItem" class="ma-4" x-large>mdi-plus-thick</v-icon>
      <v-icon @click="deleteItem" class="ma-4" x-large>mdi-minus-thick</v-icon>
    </v-row>
  </v-container>
</template>
<script>
import rankGroup from "./rank-group.vue";
export default {
  props: ["type", "schema", "exerciseType"],
  components: {
    rankGroup,
  },
  data() {
    return {
      name: "categorize",
      data: {
        baseQuestion: {
          text: null,
          audio: null,
          image: null,
        },
        items: [
          {
            question: {
              text: null,
              audio: null,
              image: null,
            },
            options: [{ image: null, text: null, audio: null, type: "text" }],
          },
        ],
      },
    };
  },
  methods: {
    setBaseQuestion(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setData(data, index) {
      this.data.items[index] = data;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },

    addItem() {
      this.data.items.push({
        question: {
          text: null,
          audio: null,
          image: null,
        },
        options: [{ image: null, text: null, audio: null, type: "text" }],
      });
    },
    deleteItem() {
      this.data.items.pop();
    },
    checkDisable() {
      if (
        this.data.baseQuestion.text == null ||
        this.data.baseQuestion.text == "" ||
        this.data.baseQuestion.audio == null
      ) {
        // this.$emit("checkDisable", true);
        return;
      }

      for (let i = 0; i < this.data.items.length; i++) {
        const item = this.data.items[i];
        if (
          item.question.text == null ||
          item.question.text == "" ||
          item.question.audio == null
        ) {
          this.$emit("checkDisable", true);
          return;
        }

        for (let j = 0; j < item.options.length; j++) {
          const option = item.options[j];
          if (
            option.info == null ||
            option.info == "" ||
            option.audio == null
          ) {
            this.$emit("checkDisable", true);
            return;
          }
        }
      }

      this.$emit("checkDisable", false);
    },
  },
  created() {
    // this.$emit("checkDisable", true);
    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";

.groub {
  border: 2px solid $myBlue;
  border-radius: 10px;
}
span {
  color: $myBlue;
  font-weight: 600;
}
</style>
