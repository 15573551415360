<template>
  <div>
    <question
      @textLoaded="setBaseData($event, 'text')"
      @fileIdLoaded="setBaseData($event)"
      :fillData="data.baseQuestion"
      :type="'base'"
    />
    <v-row class="px-10 mt-n12 justify-center">
      <v-checkbox
        v-model="data.autoPlay"
        :label="$t('Switch exercise automatically after answer')"
      ></v-checkbox>
    </v-row>
    <v-card class="tab">
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="(item, i) in data.items" :key="i">
          <div class="d-flex justify-space-between">
            <span>{{ $t("question") }} {{ i + 1 }}</span>
            <v-divider v-if="tab == i" class="mx-4" vertical></v-divider>
            <v-icon v-if="tab == i" @click.prevent.stop="copyTab(i)"
              >mdi-content-copy</v-icon
            >
          </div>
        </v-tab>
        <v-icon class="ma-2" @click="deleteTabs">mdi-minus-thick</v-icon>
        <v-icon class="ma-2" @click="addTabs">mdi-plus-thick</v-icon>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, i) in data.items" :key="i">
          <v-card flat>
            <v-row class="mt-4">
              <question
                @textLoaded="setQuestion($event, 'text', i)"
                @fileIdLoaded="setQuestion($event, 'audio', i)"
                :fillData="item.question"
                :type="'base'"
                :label="'question'"
              />
            </v-row>
            <v-row class=" justify-center align-start mt-1">
              <h3 class="ma-4">{{ $t("Answer in the correct order") }}</h3>
            </v-row>
            <tab-content
              :type="type"
              :data="item.options"
              @dataLoaded="setData($event, i)"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import tabContent from "./tabContent.vue";
export default {
  props: ["type", "schema", "exerciseType"],
  components: {
    tabContent,
  },
  data() {
    return {
      tab: null,
      name: "reorder",
      data: {
        baseQuestion: {
          text: null,
          audio: null,
          image: null,
        },
        autoPlay: false,
        items: [
          {
            question: {
              text: null,
              audio: null,
              image: null,
            },
            options: [
              {
                image: null,
                text: null,
                audio: null,
                type: "text",
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    setQuestion(data, type, index) {
      if (type == "text") {
        this.data.items[index].question.text = data;
      } else {
        this.data.items[index].question.audio = data;
      }
      this.$emit("getData", this.data);
    },
    copyTab(index) {
      const itemToCopy = JSON.parse(JSON.stringify(this.data.items[index]));
      this.data.items.push(itemToCopy);
      this.tab = this.data.items.length - 1;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    setBaseData(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    setData(data, index) {
      this.data.items[index].options = data;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    addTabs() {
      this.data.items.push({
        question: {
          text: null,
          audio: null,
          image: null,
        },
        options: [
          {
            image: null,
            text: null,
            audio: null,
            type: "text",
          },
        ],
      });
      // this.checkDisable();
    },
    deleteTabs() {
      this.data.items.pop();
      // this.checkDisable();
    },
    checkDisable() {
      if (
        this.data.baseQuestion.text == null ||
        this.data.baseQuestion.text == "" ||
        this.data.baseQuestion.audio == null
      ) {
        this.$emit("checkDisable", true);
        return;
      }

      for (let i = 0; i < this.data.items.length; i++) {
        const item = this.data.items[i];
        for (let j = 0; j < item.length; j++) {
          const option = item[j];
          if (
            option.info == null ||
            option.info == "" ||
            option.audio == null
          ) {
            this.$emit("checkDisable", true);
            return;
          }
        }
      }

      this.$emit("checkDisable", false);
    },
  },
  created() {
    this.$emit("checkDisable", true);

    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
    {
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/md/variables";

h3 {
  color: $myBlue;
  font-weight: 600;
}
.tab {
  width: 70%;
  margin: auto;
}
</style>
