<template>
  <v-container style="display: block;">
    <question
      @textLoaded="setBaseQuestion($event, 'text')"
      @fileIdLoaded="setBaseQuestion($event)"
      :type="'base'"
      :fillData="data.baseQuestion"
    />
    <question
      @textLoaded="setQuestion($event, 'text')"
      @fileIdLoaded="setQuestion($event)"
      :label="'question'"
      :fillData="data.question"
    />
    <video-preview
      :data="data.image"
      @getVideoId="setData($event)"
    ></video-preview>
  </v-container>
</template>
<script>
export default {
  props: ["type", "schema", "exerciseType"],
  data() {
    return {
      name: "coloring",
      data: {
        baseQuestion: {
          text: null,
          image: null,
          audio: null,
        },
        question: {
          text: null,
          image: null,
          audio: null,
        },
        image: null,
      },
    };
  },
  methods: {
    setBaseQuestion(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setQuestion(data, type) {
      if (type == "text") {
        this.data.question.text = data;
      } else {
        this.data.question.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setData(data) {
      this.data.image = data;
      this.checkDisable();
    },
    checkDisable() {
      if (
        (this.data.baseQuestion.text == null) |
        (this.data.baseQuestion.audio == null) |
        (this.data.baseQuestion.text == "") |
        (this.data.image == null)
      ) {
        this.$emit("checkDisable", true);
      } else {
        this.$emit("checkDisable", false);
      }
    },
  },
  created() {
    this.$emit("checkDisable", true);
    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
  },
};
</script>
