<template>
  <div>
    <question
      @textLoaded="setBaseData($event, 'text')"
      @fileIdLoaded="setBaseData($event)"
      :fillData="data.baseQuestion"
      :type="'base'"
    />
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab
        class="d-flex justify-center"
        v-for="(item, index) in data.items"
        :key="index"
      >
        <div class="d-flex justify-space-between">
          <span>{{ $t("question") }} {{ index + 1 }}</span>
          <v-divider v-if="tab == index" class="mx-4" vertical></v-divider>
          <v-icon v-if="tab == index" @click.prevent.stop="copyTab(index)"
            >mdi-content-copy</v-icon
          >
        </div>
      </v-tab>
      <v-icon class="ma-6" @click="deleteTab">mdi-minus-thick</v-icon>
      <v-icon class="ma-6" @click="addTab">mdi-plus-thick</v-icon>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in data.items" :key="index">
        <v-card flat>
          <v-row class="mt-4">
            <question
              @textLoaded="setQuestion($event, 'text', index)"
              @fileIdLoaded="setQuestion($event, 'audio', index)"
              :label="'question'"
              :fillData="item.question"
            />
          </v-row>
          <v-row class="justify-space-around mt-2 align-center">
            <v-col lg="2"> </v-col>
            <v-col class="d-flex justify-center" lg="3">
              <h4>{{ $t("question") }}</h4>
            </v-col>
            <v-col lg="2"></v-col>
            <v-col class="d-flex justify-center" lg="3">
              <h4>{{ $t("answer") }}</h4>
            </v-col>
            <v-col lg="2"></v-col>
          </v-row>
          <v-row
            v-for="(item2, i) in item.options"
            :key="i"
            class="justify-space-around align-center"
          >
            <v-col lg="2"></v-col>
            <v-col lg="2">
              <answer-option
                :data="item2.question"
                @dataLoaded="setQuestionData($event, index, i)"
              />
            </v-col>
            <v-col lg="2"><div class="divider"></div></v-col>
            <v-col lg="2">
              <answer-option
                :data="item2.answer"
                @dataLoaded="setAnswerData($event, index, i)"
              />
            </v-col>
            <v-col lg="2"></v-col>
          </v-row>
          <v-row class="justify-center">
            <v-icon class="ma-2" @click="addItem(index)" x-large
              >mdi-plus-thick</v-icon
            >
            <v-icon class="ma-2" @click="deleteItem(index)" x-large
              >mdi-minus-thick</v-icon
            >
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
export default {
  //TODO:copy the all tab is not work
  props: ["type", "schema", "exerciseType"],
  data() {
    return {
      name: "match",
      tab: 1,
      data: {
        baseQuestion: {
          text: null,
          image: null,
          audio: null,
        },
        items: [
          {
            question: {
              text: null,
              audio: null,
              image: null,
            },
            options: [
              {
                question: {
                  image: null,
                  text: null,
                  audio: null,
                  type: "text",
                },
                answer: { image: null, text: null, audio: null, type: "text" },
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    setBaseData(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    setQuestion(data, type, index) {
      if (type == "text") {
        this.data.items[index].question.text = data;
      } else {
        this.data.items[index].question.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setQuestionData(data, itab, ioption) {
      this.data.items[itab].options[ioption].question = data;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    setAnswerData(data, itab, ioption) {
      this.data.items[itab].options[ioption].answer = data;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    checkDisable() {
      if (
        this.data.baseQuestion.text == "" ||
        this.data.baseQuestion.text == null ||
        this.data.baseQuestion.audio == null
      ) {
        this.$emit("checkDisable", true);
        return; // exit the function when the base question has null or empty values
      }
      for (let i = 0; i < this.data.items.length; i++) {
        const item = this.data.items[i];
        if (item.answer == null || item.question == null) {
          this.$emit("checkDisable", true);
          return; // exit the function when an item has null or empty answer or question values
        } else {
          if (
            item.answer.info == null ||
            item.answer.info == "" ||
            item.answer.audio == null ||
            item.question.info == null ||
            item.question.info == "" ||
            item.question.audio == null
          ) {
            this.$emit("checkDisable", true);
            return; // exit the function when an answer or question has null or empty values
          }
        }
      }
      this.$emit("checkDisable", false);
    },
    addTab() {
      this.data.items.push({
        question: {
          text: null,
          audio: null,
          image: null,
        },
        options: [
          {
            question: {
              image: null,
              text: null,
              audio: null,
              type: "text",
            },
            answer: { image: null, text: null, audio: null, type: "text" },
          },
        ],
      });
      // this.checkDisable();
    },
    deleteTab() {
      this.data.items.pop();
      // this.checkDisable();
    },
    addItem(index) {
      this.data.items[index].options.push({
        question: {
          image: null,
          text: null,
          audio: null,
          type: "text",
        },
        answer: { image: null, text: null, audio: null, type: "text" },
      });
    },
    deleteItem(index) {
      this.data.items[index].options.pop();
    },
  },
  created() {
    this.$emit("checkDisable", true);
    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/md/variables";

.divider {
  border: 2px solid $myBlue;
  width: 100%;
}
h4 {
  color: $myBlue;
  font-weight: 600;
  font-size: 2vw;
}
</style>
