<template>
  <div class="d-block pa-2">
    <v-row class="mb-4">
      <question
        @textLoaded="setBaseQuestion($event, 'text')"
        @fileIdLoaded="setBaseQuestion($event)"
        :type="'base'"
        :fillData="data.baseQuestion"
      />
    </v-row>
    <v-row class="ma-auto mb-n2">
      <question
        @textLoaded="setQuestion($event, 'text')"
        @fileIdLoaded="setQuestion($event)"
        :label="'question'"
        :fillData="data.question"
      />
    </v-row>
    <v-row>
      <v-col
        v-for="(item, i) in data.items"
        :key="i"
        lg="2"
        md="3"
        sm="4"
        cols="6"
      >
        <answer-option
          @dataLoaded="setData($event, i)"
          :type="'power-point'"
          :data="item"
        />
      </v-col>
      <div class="d-flex align-center justify-center">
        <v-icon @click="deleteItem" class="ma-2">mdi-minus-thick</v-icon>
      </div>
      <div class="d-flex align-center justify-center">
        <v-icon @click="addItem" class="ma-2">mdi-plus-thick</v-icon>
      </div>
    </v-row>
    <v-row class="px-10">
      <v-col>
        <v-checkbox
          v-model="data.auto_sound"
          :label="$t('Start playing the sound automatically')"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row class="px-10 mt-n12">
      <v-col>
        <v-checkbox
          v-model="data.auto_switch"
          :label="$t('Switch slides automatically after the audio ends')"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row class="px-16  test">
      <v-col class="d-flex align-center">
        <span>{{ $t("switched after") }}</span>
        <input v-model="data.switch_after" class="mx-4" type="number" />
        <span>{{ $t("second") }}</span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["type", "schema", "exerciseType"],

  data() {
    return {
      name: "presentation",
      data: {
        baseQuestion: {
          text: null,
          image: null,
          audio: null,
        },
        question: {
          text: null,
          image: null,
          audio: null,
        },
        items: [
          {
            image: null,
            text: null,
            audio: null,
            type: "img",
          },
        ],
        auto_sound: false,
        auto_switch: false,
        switch_after: 10,
      },
    };
  },
  methods: {
    setBaseQuestion(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setQuestion(data, type) {
      if (type == "text") {
        this.data.question.text = data;
      } else {
        this.data.question.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setData(file, i) {
      this.data.items[i] = file;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },

    // checkDisable() {
    //   for (const item of this.data.items) {
    //     if (item.info == null || item.audio == null) {
    //       this.$emit("checkDisable", true);
    //       return; // exit the function when a null or undefined value is found
    //     }
    //   }
    //   this.$emit("checkDisable", false);
    // },
    addItem() {
      this.data.items.push({
        image: null,
        text: null,
        audio: null,
        type: "img",
      });
      // this.checkDisable();
    },
    deleteItem() {
      if (this.data.items.length > 1) {
        this.data.items.pop();
        // this.checkDisable();
      }
    },
  },
  created() {
    this.$emit("checkDisable", true);
    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
    // this.checkDisable();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/md/variables";

input {
  border: 1px solid $myBlue;
  height: 40px;
  width: 10%;
  text-align: center;
}
</style>
