<template>
  <div class="d-block pa-2">
    <question
      @textLoaded="setBaseQuestion($event, 'text')"
      @fileIdLoaded="setBaseQuestion($event)"
      :type="'base'"
      :fillData="data.baseQuestion"
    />
    <question
      @textLoaded="setQuestion($event, 'text')"
      @fileIdLoaded="setQuestion($event)"
      :label="'question'"
      :fillData="data.question"
    />

    <v-row class="mt-n8 justify-center px-6">
      <v-col
        v-for="(item, i) in data.items"
        :key="i"
        lg="2"
        md="3"
        sm="4"
        cols="6"
      >
        <answer-option :data="item" @dataLoaded="setData($event, i)" />
      </v-col>
      <div class="d-flex align-center">
        <v-icon @click="deleteItem" class="ma-2">mdi-minus-thick</v-icon>
      </div>
      <div class="d-flex align-center">
        <v-icon @click="addItem" class="ma-2">mdi-plus-thick</v-icon>
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["type", "schema", "exerciseType"],

  data() {
    return {
      items: 1,
      name: "memory",
      data: {
        baseQuestion: {
          text: null,
          image: null,
          audio: null,
        },
        question: {
          text: null,
          image: null,
          audio: null,
        },
        items: [
          {
            text: null,
            image: null,
            audio: null,
            type: "text",
          },
        ],
      },
    };
  },
  methods: {
    setBaseQuestion(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setQuestion(data, type) {
      if (type == "text") {
        this.data.question.text = data;
      } else {
        this.data.question.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setData(data, i) {
      this.data.items[i] = data;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    checkDisable() {
      if (
        this.data.baseQuestion.text == null ||
        this.data.baseQuestion.text == "" ||
        this.data.baseQuestion.audio == null
      ) {
        this.$emit("checkDisable", true);
        return; // exit the function when the base question has null or empty values
      }

      for (let i = 0; i < this.data.items.length; i++) {
        const item = this.data.items[i];
        if (item.info == null || item.info == "" || item.audio == null) {
          this.$emit("checkDisable", true);
          return; // exit the function when an item has null or empty values
        }
      }

      this.$emit("checkDisable", false);
    },
    addItem() {
      this.data.items.push({
        text: null,
        image: null,
        audio: null,
        type: "text",
      });
      // this.checkDisable();
    },
    deleteItem() {
      this.data.items.pop();
      // this.checkDisable();
    },
  },
  created() {
    this.$emit("checkDisable", true);

    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/md/variables";
</style>
