<template>
  <div>
    <question
      @textLoaded="setBaseQuestion($event, 'text')"
      @fileIdLoaded="setBaseQuestion($event)"
      :type="'base'"
      :fillData="data.baseQuestion"
    />
    <v-row class="px-10 mt-n12 justify-center">
      <v-checkbox
        v-model="data.autoPlay"
        :label="$t('Switch exercise automatically after answer')"
      ></v-checkbox>
    </v-row>
    <v-card class="tab-container">
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab
          class="d-flex justify-center"
          v-for="(item, index) in data.items"
          :key="index"
        >
          <div class="d-flex justify-space-between">
            <span>{{ $t("question") }} {{ index + 1 }}</span>
            <v-divider v-if="tab == index" class="mx-4" vertical></v-divider>
            <v-icon v-if="tab == index" @click.prevent.stop="copyTab(index)"
              >mdi-content-copy</v-icon
            >
          </div>
        </v-tab>
        <v-icon class="ma-6" @click="deleteItem">mdi-minus-thick</v-icon>
        <v-icon class="ma-6" @click="addItems">mdi-plus-thick</v-icon>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in data.items" :key="index">
          <v-card flat>
            <v-row class="align-start justify-center px-6">
              <v-col cols="10" lg="3">
                <answer-option
                  @dataLoaded="setData($event, index)"
                  :data="cloneObject(item)"
                  :type="'true-false'"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["type", "schema", "exerciseType"],

  data() {
    return {
      name: "true_or_false",
      tab: null,
      answer1: false,
      answer2: false,
      data: {
        baseQuestion: {
          text: null,
          image: null,
          audio: null,
        },
        autoPlay: false,
        items: [
          {
            question: {
              image: null,
              text: null,
              audio: null,
              type: "text",
              answer: true,
            },
            answer: true,
          },
        ],
      },
    };
  },
  methods: {
    setBaseQuestion(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
    },

    copyTab(index) {
      const itemToCopy = JSON.parse(JSON.stringify(this.data.items[index]));
      this.data.items.push(itemToCopy);
      this.tab = this.data.items.length - 1;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },

    setData(data, index) {
      let answer = data.answer;
      delete data.answer;
      this.data.items[index].question = data;
      Object.assign(this.data.items[index], { answer: answer });
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    cloneObject(data) {
      return Object.assign(data.question, { answer: data.answer });
    },
    addItems() {
      this.data.items.push({
        question: {
          image: null,
          text: null,
          audio: null,
          type: "text",
          answer: true,
        },
        answer: true,
      });
      // this.checkDisable();
    },
    deleteItem() {
      this.data.items.pop();
      // this.checkDisable();
    },
    checkDisable() {
      if (
        this.data.baseQuestion.text == null ||
        this.data.baseQuestion.text == "" ||
        this.data.baseQuestion.audio == null
      ) {
        this.$emit("checkDisable", true);
        return;
      }

      for (let i = 0; i < this.data.items.length; i++) {
        const item = this.data.items[i];
        if (item.info == null || item.info == "" || item.audio == null) {
          this.$emit("checkDisable", true);
          return;
        }
      }

      this.$emit("checkDisable", false);
    },
  },

  created() {
    this.$emit("checkDisable", true);
    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.tab-container {
  width: 70%;
  margin: auto;
}
</style>
