<template>
  <v-container style="display: block;">
    <question
      @textLoaded="setBaseData($event, 'text')"
      @fileIdLoaded="setBaseData($event)"
      :fillData="data.baseQuestion"
    />
    <v-row class="px-10 mt-n12 justify-center">
      <v-checkbox
        v-model="data.autoPlay"
        :label="$t('Switch exercise automatically after answer')"
      ></v-checkbox>
    </v-row>
    <chooseTabs
      :check="check"
      :type="type"
      :schema="data.items"
      @getData="setData($event)"
      @checkDisable="checkDisable($event)"
    />
  </v-container>
</template>
<script>
import chooseTabs from "./chooseTabs.vue";
export default {
  props: ["type", "schema", "exerciseType"],
  data() {
    return {
      name: "choose",
      data: {
        baseQuestion: {
          text: null,
          audio: null,
          image: null,
        },
        autoPlay: false,

        items: [
          {
            question: {
              text: null,
              audio: null,
              image: null,
            },
            options: [
              {
                image: null,
                text: null,
                audio: null,
                type: "text",
              },
            ],
          },
        ],
      },
      check: true,
    };
  },

  components: {
    chooseTabs,
  },
  methods: {
    setBaseData(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
      this.check = !this.check;
    },
    setData(data) {
      this.data.items = data;
      this.$emit("getData", this.data);
    },
    checkDisable(check) {
      if (
        (this.data.baseQuestion.text == null) |
        (this.data.baseQuestion.text == "") |
        (this.data.baseQuestion.audio == null) |
        check
      ) {
        this.$emit("checkDisable", true);
        return;
      }
      this.$emit("checkDisable", false);
    },
  },
  created() {
    this.$emit("checkDisable", true);

    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
  },
};
</script>
