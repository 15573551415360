<template>
  <v-card class="tab-container">
    <v-tabs v-if="check1" v-model="tab" background-color="primary" dark>
      <v-tab v-for="(item, i) in data" :key="i">
        <div class="d-flex justify-space-between align-center">
          <span>{{ $t("question") }} {{ i + 1 }}</span>
          <v-divider v-if="tab == i" class="mx-4" vertical></v-divider>
          <v-icon v-if="tab == i" @click.prevent.stop="copyTab(i)"
            >mdi-content-copy</v-icon
          >
        </div>
      </v-tab>
      <v-icon class="ma-6" @click="deleteItem">mdi-minus-thick</v-icon>
      <v-icon class="ma-6" @click="addItem">mdi-plus-thick</v-icon>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in data" :key="index">
        <v-card flat>
          <v-row class="mt-6 mb-6">
            <question
              @fileIdLoaded="setBaseData($event, index)"
              @textLoaded="setBaseData($event, index, 'text')"
              :fillData="item.question"
              :label="'question'"
            />
          </v-row>
          <v-row class="mt-n8 justify-center align-center px-6">
            <v-col v-for="(item2, i) in item.options" :key="i" cols="10" lg="3">
              <h4
                class="text-center"
                v-bind:style="{ visibility: i == 0 ? 'visible' : 'hidden' }"
              >
                {{ $t("correct_answer") }}
              </h4>

              <answer-option
                @dataLoaded="setData($event, index, i)"
                v-bind:data="type == 'edit' ? item2 : null"
                :type="'choose-answer'"
              />
            </v-col>
            <div>
              <v-icon @click="addOption(index)" class="ma-4" x-large
                >mdi-plus-thick</v-icon
              >
            </div>
            <div>
              <v-icon @click="deleteOption(index)" class="ma-4" x-large
                >mdi-minus-thick</v-icon
              >
            </div>
          </v-row>
          <v-row> </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
export default {
  props: ["type", "schema", "check"],
  data() {
    return {
      data: null,
      dialog: false,
      src: null,
      tab: 0,
      check1: true,
    };
  },
  methods: {
    // test(index) {
    //   if ((index == 0) | (index == this.data.length)) {
    //     return;
    //   } else {
    //     const fromIndex = index;
    //     const toIndex = index - 1;
    //     const itemToMove = this.data.splice(fromIndex, 1)[0];
    //     this.data.splice(toIndex, 0, itemToMove);
    //     this.tab = index - 1;
    //     this.check1 = false;
    //     this.check1 = true;
    //     // console.log(this.data);
    //   }
    // },
    copyTab(index) {
      const itemToCopy = JSON.parse(JSON.stringify(this.data[index]));
      this.data.push(itemToCopy);
      this.tab = this.data.length - 1;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    setBaseData(data, index, type) {
      if (type == "text") {
        this.data[index].question.text = data;
      } else {
        this.data[index].question.audio = data;
      }
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    setData(data, index, i) {
      this.data[index].options[i] = data;
      this.$emit("getData", this.data);
      // this.checkDisable();
    },
    addItem() {
      this.data.push({
        question: {
          text: null,
          audio: null,
          image: null,
        },
        options: [{ image: null, text: null, audio: null, type: "text" }],
      });
      this.$emit("getData", this.data);
      // this.checkDisable();
    },

    deleteItem() {
      this.data.pop();
      this.$emit("getData", this.data);
      // this.checkDisable();
    },

    addOption(index) {
      this.data[index].options.push({
        image: null,
        text: null,
        audio: null,
        type: "text",
      });
    },
    deleteOption(index) {
      this.data[index].options.pop();
    },
    checkDisable() {
      for (let i = 0; i < this.data.length; i++) {
        const item = this.data[i];
        if (
          item.question.text == null ||
          item.question.text == "" ||
          item.question.audio == null
        ) {
          this.$emit("checkDisable", true);
          return; // exit the function when a question has null or empty values
        }
        for (let j = 0; j < item.options.length; j++) {
          const option = item.options[j];
          if (
            option.info == null ||
            option.info == "" ||
            option.audio == null
          ) {
            this.$emit("checkDisable", true);
            return; // exit the function when an option has null or empty values
          }
        }
      }

      this.$emit("checkDisable", false);
    },
  },
  created() {
    this.data = this.schema;
    this.$emit("getData", this.data);
    // this.checkDisable();
  },
  watch: {
    check(val) {
      this.checkDisable();
    },
  },
};
</script>
<style lang="scss">
.tab-container {
  width: 90%;
  margin: auto;
  border-radius: 20px;
}
</style>
