<template>
  <div>
    <question
      @textLoaded="setBaseQuestion($event, 'text')"
      @fileIdLoaded="setBaseQuestion($event)"
      :fillData="data.baseQuestion"
      :type="'base'"
    />
    <v-card class="tab">
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="(item, i) in data.items" :key="i"
          >{{ $t("question") }} {{ i + 1 }}
        </v-tab>
        <v-icon class="ma-6" @click="deleteItems">mdi-minus-thick</v-icon>
        <v-icon class="ma-6" @click="addItems">mdi-plus-thick</v-icon>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in data.items" :key="index">
          <v-card class="my-4" flat>
            <question
              @textLoaded="setQuestion($event, 'text', index)"
              @fileIdLoaded="setQuestion($event, '', index)"
              @addedGab="addedGab($event, index)"
              @deleteGab="deleteGab(index)"
              @deleteAll="deleteAll(index)"
              :fillData="item.question"
              :label="'question'"
              :isGabs="true"
            />
            <v-row class="justify-center">
              <v-col v-for="(data, i) in item.correct_gap" :key="i" lg="4">
                <answer-option
                  @dataLoaded="setData($event, index, i)"
                  :data="data.value"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["type", "schema", "exerciseType"],
  data() {
    return {
      tab: 1,
      name: "fill_gaps",
      inputText: "",
      gabs: 0,
      data: {
        baseQuestion: {
          text: null,
          audio: null,
          image: null,
        },
        autoPlay: true,
        items: [
          {
            question: {
              text: null,
              image: null,
              audio: null,
            },
            correct_gap: [],
          },
        ],
      },
    };
  },
  methods: {
    setBaseQuestion(data, type) {
      if (type == "text") {
        this.data.baseQuestion.text = data;
      } else {
        this.data.baseQuestion.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setQuestion(data, type, index) {
      if (type == "text") {
        this.data.items[index].question.text = data;
      } else {
        this.data.items[index].question.audio = data;
      }
      this.$emit("getData", this.data);
    },
    setData(data, index, i) {
      this.data.items[index].correct_gap[i].value = data;
      this.$emit("getData", this.data);
    },
    addItems() {
      this.data.items.push({
        question: {
          text: null,
          image: null,
          audio: null,
        },
        correct_gap: [],
      });
      this.cheakDisable();
    },
    deleteItems() {
      this.data.items.pop();
      this.cheakDisable();
    },
    addedGab(gapPostion, index) {
      this.data.items[index].correct_gap.push({
        index: gapPostion,
        value: {
          text: null,
          image: null,
          audio: null,
          type: "text",
        },
      });
    },
    deleteGab(index) {
      this.data.items[index].correct_gap.pop();
    },
    deleteAll(index) {
      this.data.items[index].correct_gap = [];
    },
  },
  created() {
    this.$emit("checkDisable", true);
    if (this.type == "edit") {
      if (this.exerciseType === this.name) {
        this.data = this.schema;
      }
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/md/variables";

h3 {
  color: $myBlue;
  font-weight: 600;
}
.tab {
  width: 70%;
  margin: auto;
}
</style>
